import React, { useState } from "react";
import "./styles.less";
import { Carousel } from "antd";
import { useDeviceDetect } from "../../utils/hooks";

const ScrollViewer = (props) => {
  const [current, setCurrent] = useState(0);
  const { isMobile } = useDeviceDetect();
  if (isMobile) {
    return (
      <div className="scroll-viewer-wrapper">
        <div className="carousel-viewer">
          <Carousel afterChange={(i) => setCurrent(i)} dots={false}>
            {props.images.map((image, i) => {
              return (
                <div key={`image-${i}`} className="photo-wrapper">
                  <img src={image.file.url} />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="status-mobile">
          <div className="slide-status">
            {current + 1}/{props.images.length}
          </div>
          <h1>{props.title}</h1>
          <p>{props.subtitle}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="scroll-viewer-wrapper">
        <div className="simple-title">
          <h1>{props.title}</h1>
          <p>{props.subtitle}</p>
        </div>

        <div className="scroll-viewer">
          {props.images.map((image, i) => {
            return (
              <div
                key={`col-${i}`}
                className={`background ${isMobile ? "mobile" : "parallax"}`}
                style={{
                  backgroundImage: `url(${image.file.url})`,
                  opacity: props.isLoading ? 0 : 1,
                }}
                data-img-width="auto"
                data-img-height="1064"
                data-diff="0"
              />
            );
          })}

          {props.footer}
        </div>
      </div>
    );
  }
};

export default ScrollViewer;
